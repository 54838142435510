module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nawach Kuptimanus","short_name":"kuptiman","description":"Nawach Kuptimanus Dev Portfolio Website","lang":"en","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#fffaf7","theme_color":"#232129","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5d08e733db2605062620d1695ed0d89"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
